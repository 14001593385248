import React from 'react'
import { StyledSection } from '../styles'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../layouts/siteLayout'
import Landing2 from '../components/landing2'

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  div {
    padding: 3rem 0;
    max-width: 57ch;

    h2 {
      margin: 0;
      padding-top: 2rem;
    }

    h3 {
      margin: 0;
      padding-bottom: 1rem;
    }

    .all-stars {
      padding-top: 3rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
    }
    p {
      padding: 1rem 0 1rem 0;
    }

    ul {
      padding: 1rem 0 0 2rem;
    }

    small {
      display: flex;
      padding-top: 3rem;
    }
  }
`

const SponsorshipPage = () => {
  const sponsorshipData = useStaticQuery(graphql`
    query sponsorshipPlayersQuery {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          players {
            player
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        players: { player },
      },
    },
  } = sponsorshipData

  return (
    <Layout>
      <Landing2 header="Sponsorships" />
      <StyledSection>
        <StyledContent>
          <div>
          <h2>Double Diamond Level</h2>
            <h3>$15,000</h3>
            <span className="bold">
              Includes: Your team of 3 golfers AND: <br />
              Special VIP Guest – Western Canada
            </span>
            <ul>
              <li> Your company recognized as a Diamond sponsor at the tournament</li>
              <li> One Hole Sponsorship</li>
              <li>Welcome Gift for all players</li>
              <li> Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>
            <h2>Diamond Level</h2>
            <h3>$10,000</h3>
            <span className="bold">
              Includes: Your team of 3 golfers AND: <br />
              Vice President of Operations – Western Canada
            </span>
            <ul>
              <li> Your company recognized as a Diamond sponsor at the tournament</li>
              <li> One Hole Sponsorship</li>
              <li>Welcome Gift for all players</li>
              <li> Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>
            <h2>Platinum Level</h2>
            <h3>$8,000</h3>
            <span className="bold">
              Includes: Your team of 3 golfers AND your choice of one of the Circle K All Stars listed below:
            </span>
            <ul>
              <li> Your company recognized as a Platinum sponsor at the tournament</li>
              <li> One Hole Sponsorship</li>
              <li> Welcome Gift for all players</li>
              <li>Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>

            <h3 className="all-stars">The Circle K Western Golf All-Stars</h3>
            {/* The list of players */}
            <ul dangerouslySetInnerHTML={{ __html: player }}></ul>

            <h2>Gold Level </h2>
            <h3>$6,000</h3>
            <span className="bold">Includes: Your team of 4 golfers.</span>
            <ul>
              <li>Your company recognized as a Gold sponsor at the tournament</li>
              <li>Welcome Gift for all players </li>
              <li>Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>
            <h2>Silver Level</h2>
            <h3>$3,000</h3>
            <span className="bold"> Includes: 2 golfers.</span>
            <ul>
              <li> Your company recognized as a Silver sponsor at the tournament</li>
              <li> Welcome Gift for all players</li>
              <li>Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>
            <h2>Bronze Level</h2>
            <h3>$1,500</h3>
            <span className="bold">Includes: 1 golfer.</span>
            <ul>
              <li> Your company recognized as a Bronze sponsor at the tournament</li>
              <li> Welcome Gift for all players</li>
              <li> Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>

            <h2>Hole in One Sponsorship</h2>
            <h3>$1,000 plus applicable insurance fees for your hole in one prize</h3>
            <span className="bold">Includes: 1 golfer. </span>
            <ul>
              <li>Your company recognized as a Hole in One sponsor at the tournament</li>
              <li>One Hole in One Sponsorship</li>
              <li>Welcome gift for all players</li>
              <li>Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>

            <h2>Single Entry</h2>
            <h3>$850</h3>
            <span className="bold">Includes entry fee for 1 golfer. </span>
            <ul>
              <li> Welcome gift for all players</li>
              <li>Team prize for each player</li>
              <li>A complete day of fun with complimentary food, drinks and a full dinner</li>
            </ul>

            <small>
              The above Sponsorship Levels will be accepted on a first come first served basis. Instructions on how to
              register for the tournament will be sent out prior to registration opening.
            </small>
          </div>
        </StyledContent>
      </StyledSection>
    </Layout>
  )
}

export default SponsorshipPage
